import { lazy } from 'react';

/**
 * @description Lazily load the `CandidateSearch` component
 *
 * {@link https://react.dev/reference/react/lazy#suspense-for-code-splitting 🔗 Lazy Loading components with Suspense}
 */
export const CandidateSearchAsync = lazy(async function () {
  return import('./CandidateSearch').then((module) => ({ default: module.CandidateSearch }));
});
