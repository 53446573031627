import { ProfileDto } from '@crowdcoursing/api/openapi';
import { useQueryClient } from '@tanstack/react-query';
import { createBrowserRouter, createMemoryRouter, Navigate, Outlet, RouteObject } from 'react-router-dom';

import { AdminPageAsync } from '~ui/pages/AdminPage/AdminPageAsync';
import { LegacyAppNavigationLayoutAsync } from '~ui/pages/AppNavigationLayout/AppNavigationLayoutAsync';
import { CandidateSearchAsync } from '~ui/pages/CandidateSearch/CandidateSearchAsync';
import { CreateProfileAsync } from '~ui/pages/CreateProfile/CreateProfileAsync';
import { HomeAsync } from '~ui/pages/Home/HomeAsync';
import { LoginAsync } from '~ui/pages/Login/LoginAsync';
import { LoginVerificationAsync } from '~ui/pages/LoginVerification/LoginVerificationAsync';
import { Logout } from '~ui/pages/Logout/Logout';
import { ArchivedAsync } from '~ui/pages/Notifications/ArchivedAsync';
import { InboxAsync } from '~ui/pages/Notifications/InboxAsync';
import { OrganizationAsync } from '~ui/pages/Organization/OrganizationAsync';
import { OrganizationsPage } from '~ui/pages/OrganizationsPage/OrganizationsPage';
import { ResumeBuilderAsync } from '~ui/pages/ResumeBuilder/ResumeBuilderAsync';
import { SiteAdminPageAsync } from '~ui/pages/SiteAdminPage/SiteAdminPageAsync';
import { UserProfileAsync } from '~ui/pages/UserProfile/UserProfileAsync';

import { AppLayoutAsync } from '../../AppLayoutAsync';
import { AdminSchoolMembers } from '../../components/Admin';
import { CheckIn as EventCheckIn } from '../../components/Events/CheckIn';
import { CheckInCode as EventCheckInCode } from '../../components/Events/CheckInCode';
import CreateEvent from '../../components/Events/CreateSession';
import EditEvent from '../../components/Events/EditEvent';
import EventMembers from '../../components/Events/EventMembers';
import Events from '../../components/Events/Events';
import ViewEvent from '../../components/Events/ViewEvent';
import { Cancel as PaymentCancel, Success as PaymentSuccess } from '../../components/Payments';
import { CreatePod, MySchedules, PodMembers, Search, UpdatePod, ViewPod } from '../../components/Pods';
import { CheckIn as PodCheckIn } from '../../components/Pods/CheckIn';
import { CheckInCode as PodCheckInCode } from '../../components/Pods/CheckInCode';
import { ProfileRefresh } from '../../components/Profiles/PaymentAccountForm';
import { RedirectToLogin } from '../../components/RedirectToLogin';
import { useSelector } from '../../hooks';
import { CheckInPage } from '../../ui/pages/CheckIn/CheckInPage';
import { QRCodePage } from '../../ui/pages/CheckIn/QRCodePage';
import { isTokenExpired } from '../../utilities/utils';
import { RawRoute, Route } from './Route';

function useShouldRedirectToLogin() {
  const queryClient = useQueryClient();
  const isProfileReady = queryClient.getQueryCache().find<ProfileDto>({ queryKey: ['loginWhoAmI'] })?.state
    .data?.isProfileReady;
  const token = useSelector(({ auth: { token } }) => token);
  return Boolean(!token || isTokenExpired(token) || (status === 'success' && !isProfileReady));
}

function WithRedirectToLogin({ children }: React.PropsWithChildren) {
  const shouldRedirectToLogin = useShouldRedirectToLogin();
  if (shouldRedirectToLogin) {
    return <RedirectToLogin />;
  }

  return <>{children}</>;
}

export const routes: RouteObject[] = [
  // Base route; Redirect to login if not authenticated; Redirect to home if authenticated
  {
    element: (
      <WithRedirectToLogin>
        <Navigate to={Route.home()} />
      </WithRedirectToLogin>
    ),
    path: RawRoute.index,
  },

  // Routes not requiring authentication; No nav menu
  {
    children: [
      { element: <LoginAsync />, path: RawRoute.login },
      { element: <Logout />, path: RawRoute.logout },
      { element: <LoginVerificationAsync />, path: RawRoute.verify },
      { element: <CreateProfileAsync />, path: RawRoute.profileCreate },
    ],
    element: <LegacyAppNavigationLayoutAsync />,
  },

  // Routes requiring authentication; No nav menu
  {
    children: [
      {
        children: [
          { element: <PodCheckInCode />, path: RawRoute.podQr },
          { element: <EventCheckInCode />, path: RawRoute.eventQr },
          { element: <QRCodePage />, path: RawRoute.bubbleQr },
          { element: <PodCheckIn />, path: RawRoute.podCheckIn },
          { element: <EventCheckIn />, path: RawRoute.eventCheckIn },
          { element: <CheckInPage />, path: RawRoute.bubbleCheckIn },
        ],
        element: <LegacyAppNavigationLayoutAsync />,
      },
    ],
    element: (
      <WithRedirectToLogin>
        <Outlet />
      </WithRedirectToLogin>
    ),
  },

  // Routes requiring authentication; Nav menu
  {
    children: [
      // with app navigation layout
      {
        children: [
          // home
          { element: <HomeAsync />, path: RawRoute.home },

          { element: <MySchedules />, path: RawRoute.schedules },

          // admin
          { element: <AdminPageAsync />, path: RawRoute.admin },
          { element: <AdminSchoolMembers />, path: RawRoute.adminSchoolMembership },
          { element: <SiteAdminPageAsync />, path: RawRoute.adminSite },

          // organizations
          {
            element: <OrganizationsPage />,
            path: RawRoute.organizations,
          },
          {
            element: <OrganizationAsync />,
            path: RawRoute.organization,
          },

          // notifications
          { element: <InboxAsync />, path: RawRoute.notifications },
          { element: <ArchivedAsync />, path: RawRoute.notificationsArchived },

          // payments
          { element: <ProfileRefresh />, path: RawRoute.profileRefresh },
          { element: <PaymentSuccess />, path: RawRoute.paymentSuccess },
          { element: <PaymentCancel />, path: RawRoute.paymentCancel },

          // profile
          { element: <UserProfileAsync />, path: RawRoute.profile },

          // rooms
          { element: <ViewPod />, path: RawRoute.podView },
          { element: <UpdatePod />, path: RawRoute.podUpdate },
          { element: <CreatePod />, path: RawRoute.podCreate },
          { element: <PodMembers />, path: RawRoute.podMembers },
          { element: <CreateEvent />, path: RawRoute.roomCreateEvent },
          { element: <EditEvent />, path: RawRoute.roomEditEvent },
          { element: <ViewEvent />, path: RawRoute.roomViewEvent },

          // events
          { element: <ViewEvent />, path: RawRoute.eventView },
          { element: <EditEvent />, path: RawRoute.eventEdit },
          { element: <EventMembers />, path: RawRoute.eventMembers },
          { element: <CreateEvent />, path: RawRoute.eventCreate },
          { element: <Events />, path: RawRoute.sessions },

          // resume
          { element: <ResumeBuilderAsync />, path: RawRoute.resumeBuilder },

          // search
          { element: <Search />, path: RawRoute.searchRoomsAndEvents },
          { element: <CandidateSearchAsync />, path: RawRoute.searchCandidates },
        ],
        element: <AppLayoutAsync />,
      },
    ],
    element: (
      <WithRedirectToLogin>
        <Outlet />
      </WithRedirectToLogin>
    ),
  },
];

export const browserRouter = createBrowserRouter(routes);

export const memoryRouter = createMemoryRouter(routes);
