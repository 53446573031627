import { Box, BoxProps } from '@mui/material';
import {
  DataGridPro,
  DataGridProProps,
  GridToolbarProps,
  GridToolbarQuickFilter,
  GridValidRowModel,
} from '@mui/x-data-grid-pro';
import { memo, useRef } from 'react';
import { Except } from 'type-fest';

import { ColumnData, RowData } from '~types/datagrid';

import { PrintWidget } from '../../atoms/DataGrid/Widgets/PrintWidget';
import {
  EmptyState,
  EmptyStateProps,
  ErrorState,
  ErrorStateProps,
  ListLoadingState,
  ListLoadingStateProps,
} from '../../atoms/SectionStates';

const MIN_HEIGHT: React.CSSProperties['height'] = '372px';
const MAX_HEIGHT: React.CSSProperties['height'] = '813px';

type ToolbarCustomProps = { widgetOptions?: { show?: boolean; showPrintWidget?: boolean } };

function getToolbar({ widgetOptions = {} }: ToolbarCustomProps) {
  const { show = true, showPrintWidget = true } = widgetOptions;

  return function Toolbar({ showQuickFilter, quickFilterProps, ...props }: GridToolbarProps) {
    return (
      <Box
        alignItems="end"
        display="grid"
        gap="2rem"
        gridAutoColumns="minmax(0, 1fr)"
        gridAutoFlow="column"
        padding="1rem 1rem 1.5rem"
      >
        {Boolean(showQuickFilter) && <GridToolbarQuickFilter {...quickFilterProps} />}
        {show && (
          <>
            {!props.children && <i />}
            <Box display="grid" gap="1rem" gridAutoColumns="minmax(0, auto)" gridAutoFlow="column" justifyContent="end">
              {props.children}
              {showPrintWidget && <PrintWidget slotProps={{ button: { edge: 'end' } }} />}
            </Box>
          </>
        )}
      </Box>
    );
  };
}

const sharedSlotProps: ListLoadingStateProps['slotProps'] = {
  container: {
    height: `calc(${MIN_HEIGHT} - 2px)`,
    sx: {
      border: (theme) => `1px solid ${theme.palette.crowdCoursing.GREY[400]?.main ?? 'transparent'}`,
      borderRadius: '0.25rem',
    },
  },
};

export type DataGridBaseProps<T extends GridValidRowModel> = Except<
  DataGridProProps<RowData<T>>,
  'columns' | 'rows'
> & {
  /** Column data. This should be memoized. */
  columns: readonly ColumnData<T>[];
  /** Row data. This should be memoized. */
  rows: readonly RowData<T>[];
  headerWidgets?: React.ReactNode;
  status?: Utils.QueryStatus;
  toolbarCustomProps?: ToolbarCustomProps;
};
export type DataGridSlotProps<T extends GridValidRowModel> = Pick<
  DataGridProProps<RowData<T>>,
  'slotProps' | 'slots'
> & {
  slotProps?: {
    container?: BoxProps;
    empty?: EmptyStateProps;
    error?: ErrorStateProps;
    loading?: ListLoadingStateProps;
  };
  slots?: {
    empty?: React.ReactNode;
    error?: React.ReactNode;
    loading?: React.ReactNode;
  };
};
export type DataGridProps<T extends GridValidRowModel> = Except<DataGridBaseProps<T>, 'slotProps' | 'slots'> &
  DataGridSlotProps<T>;

function NoRowsOverlayWrapper(props?: EmptyStateProps) {
  return function NoRowsOverlay() {
    return <EmptyState slotProps={sharedSlotProps} {...props} />;
  };
}

export function DataGrid<T extends GridValidRowModel>({
  headerWidgets,
  status = 'success',
  toolbarCustomProps = {},
  ...props
}: DataGridProps<T>) {
  const ref = useRef<HTMLDivElement>(null);

  const noRowsOverlay = memo(
    NoRowsOverlayWrapper({
      ...props.slotProps?.empty,
      slotProps: {
        ...props.slotProps?.empty?.slotProps,
        container: {
          ...props.slotProps?.empty?.slotProps?.container,
          height: '100%',
        },
      },
    })
  );

  return (
    <Box
      {...props.slotProps?.container}
      maxHeight={ref?.current?.style.maxHeight ?? MAX_HEIGHT}
      minHeight={ref?.current?.style.minHeight ?? MIN_HEIGHT}
    >
      {status === 'error' &&
        (props.slots?.error ?? <ErrorState slotProps={sharedSlotProps} {...props.slotProps?.error} />)}
      {status === 'pending' &&
        (props.slots?.loading ?? (
          <ListLoadingState count={7} slotProps={sharedSlotProps} {...props.slotProps?.loading} />
        ))}
      {status === 'success' && (
        /**
         * We receive the following typescript error;
         *
         * @example
         *  Types of property 'type' are incompatible.
              Type 'GridColType | undefined' is not assignable to type '"singleSelect"'.
                Type 'undefined' is not assignable to type '"singleSelect"'.ts(2322)
         */
        // @ts-expect-error `type` is allowed to be `undefined` according to `node_modules/@mui/x-data-grid/models/colDef/gridColDef.d.ts`
        <DataGridPro
          disableColumnFilter
          filterMode="client"
          getRowHeight={() => 'auto'}
          pageSizeOptions={[5, 10, 25, 50, 100]}
          pagination
          {...props}
          getRowId={(row) => row.rowId}
          initialState={{
            ...props.initialState,
            pagination: {
              ...props.initialState?.pagination,
              paginationModel: { pageSize: 5, ...props.initialState?.pagination?.paginationModel },
            },
          }}
          ref={ref}
          slotProps={{
            ...props.slotProps,
            footer: {
              ...props.slotProps?.footer,
              sx: { display: 'flex', justifyContent: 'center', ...props.slotProps?.footer?.sx },
            },
            toolbar: {
              children: headerWidgets,
              showQuickFilter: true,
              ...props.slotProps?.toolbar,
              quickFilterProps: { debounceMs: 333, ...props.slotProps?.toolbar?.quickFilterProps },
              style: { paddingBottom: 0, ...props.slotProps?.toolbar?.style },
            },
          }}
          slots={{ noRowsOverlay, toolbar: getToolbar(toolbarCustomProps), ...props.slots }}
          sx={{
            ' .MuiDataGrid-cell': { alignItems: 'baseline', padding: '0.5rem' },
            ' .MuiDataGrid-columnHeader': {
              ' .MuiDataGrid-columnHeaderTitle': { fontWeight: 'bold' },
              '&:focus-within': { outline: 'unset' },
            },
            ...(props.rows?.length
              ? {}
              : {
                  ' .MuiDataGrid-overlayWrapper': { display: 'inline-grid !important', width: '100%' },
                  ' .MuiDataGrid-virtualScroller': { overflow: 'hidden !important' },
                }),
            height: 'auto',
            maxHeight: MAX_HEIGHT,
            minHeight: MIN_HEIGHT,
            ...props.sx,
          }}
        />
      )}
    </Box>
  );
}
