import { configureGraphQL } from '@crowdcoursing/api/graphql';
import { configureOpenApi } from '@crowdcoursing/api/openapi';
import { Suspense } from 'react';
import { RouterProvider } from 'react-router-dom';

import { ReduxProvider, store } from '~services/providers/ReduxProvider';
import { ThemeProvider } from '~services/providers/ThemeProvider';

import { LoginActions, loginWithToken } from './actions/login';
import ToastNotificationsContainer from './components/Shared/ToastNotificationsContainer';
import { serviceOptions } from './services/ApiService';
import { createInstance } from './services/AxiosInstance';
import { QueryProvider } from './services/providers/QueryProvider';
import { browserRouter, memoryRouter } from './services/router/router';

serviceOptions.axios = createInstance(store);

configureOpenApi(() => {
  return {
    baseUrl: import.meta.env.REACT_APP_BACKEND_URL,
    headers: { Authorization: `Bearer ${store.getState().auth.token}` },
    unauthorizedCallback() {
      store.dispatch(LoginActions.logout());
    },
  };
});

configureGraphQL({
  fetcherOptions: {
    config: {
      baseUrl: import.meta.env.REACT_APP_BACKEND_URL,
      async onResponse(response) {
        if (response.errors?.[0]?.extensions?.code === 'AUTH_NOT_AUTHENTICATED') {
          store.dispatch(LoginActions.logout());
        }
      },
    },
    headers() {
      return { Authorization: `Bearer ${store.getState().auth.token}` };
    },
  },
});

// expose a helper for e2e to automatically login
if (store.getState().features.loginAsTestUser) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (window as any)['e2e:login'] = (token: string) => loginWithToken(token, store.dispatch);
}

export interface AppProps {
  useMemoryRouter?: boolean;
}

export const App = ({ useMemoryRouter }: AppProps) => {
  return <RouterProvider router={useMemoryRouter ? memoryRouter : browserRouter} />;
};

const WrappedApp = () => {
  return (
    <ReduxProvider>
      <QueryProvider>
        <ThemeProvider>
          <ToastNotificationsContainer />
          <Suspense>
            <App />
          </Suspense>
        </ThemeProvider>
      </QueryProvider>
    </ReduxProvider>
  );
};

export default WrappedApp;
