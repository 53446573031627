import { useMemo } from 'react';

import { useGetOrganizationsQuery } from '../services/graphql/generated/react-query';

export const useOrganizations = () => {
  const {
    data: organizationsData,
    isLoading: isOrganizationsLoading,
    error: organizationsError,
  } = useGetOrganizationsQuery();

  const organizations = useMemo(() => {
    if (!organizationsData?.organizations) return [];
    return organizationsData.organizations.map((org) => ({
      address: org.address,
      canCreateEvents: org.canCreateEvents,
      description: org.description,
      id: org.id,
      isExternal: org.isExternal,
      name: org.name,
    }));
  }, [organizationsData?.organizations]);

  return {
    error: organizationsError,
    isLoading: isOrganizationsLoading,
    organizations,
  };
};
