import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { useFetch } from '../fetcher';
import * as Schema from './CrowdCoursingApiSchemas';

export const CandidateSearchDocument = `
    query CandidateSearch($after: String, $before: String, $first: Int, $last: Int, $order: [ResumeSummaryRedactedSortInput!], $where: ResumeSummaryRedactedFilterInput) {
  resumesRedacted(
    after: $after
    before: $before
    first: $first
    last: $last
    order: $order
    where: $where
  ) {
    nodes {
      id
      userId
      certifications
      skills
      graduationYear
      gpaUnweighted
      gpaWeighted
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    totalCount
  }
}
    `;

export const useCandidateSearchQuery = <TData = Schema.CandidateSearchQuery, TError = unknown>(
  variables?: Schema.CandidateSearchQueryVariables,
  options?: Omit<UseQueryOptions<Schema.CandidateSearchQuery, TError, TData>, 'queryKey'> & {
    queryKey?: UseQueryOptions<Schema.CandidateSearchQuery, TError, TData>['queryKey'];
  },
) => {
  return useQuery<Schema.CandidateSearchQuery, TError, TData>({
    queryFn: useFetch<Schema.CandidateSearchQuery, Schema.CandidateSearchQueryVariables>(CandidateSearchDocument).bind(
      null,
      variables,
    ),
    queryKey: variables === undefined ? ['CandidateSearch'] : ['CandidateSearch', variables],
    ...options,
  });
};

useCandidateSearchQuery.getKey = (variables?: Schema.CandidateSearchQueryVariables) =>
  variables === undefined ? ['CandidateSearch'] : ['CandidateSearch', variables];
